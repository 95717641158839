<template>
  <div class="container bg-white" id="pelepasan">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.pelepasan.title') }}</h3>
        <div class="divider"></div>
        <p>{{ $t('tos.pelepasan.line1') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'definisi',
};
</script>
