<template>
  <div class="term--service-wrapper">
    <banner :banner-text="bannerText" />
    <div class="row">
      <div class="col-12 col-md-6 text-center d-flex justify-center main--image-wrapper">
        <img
          class="main--image"
          src="https://rentfix.s3-ap-southeast-1.amazonaws.com/static/img/static/photo-home-owner-auckland.jpg"
        />
      </div>
      <div v-html="$t('tos.opening')" class="col-12 col-md-6 opening"></div>
      <div class="col-12 intro--wrapper">
        <div class="introduction" v-html="$t('tos.contentIntro')"></div>
      </div>
      <div class="col-12 content--wrapper">
        <bookmark class="mb-4"></bookmark>
        <definisi class="mb-4"></definisi>
        <akun class="mb-4"></akun>
        <harga class="mb-4"></harga>
        <harga-paket class="mb-4"></harga-paket>
        <listing-properti class="mb-4"></listing-properti>
        <penolakan class="mb-4"></penolakan>
        <pelepasan class="mb-4"></pelepasan>
        <ganti-rugi class="mb-4"></ganti-rugi>
        <hukum class="mb-4"></hukum>
        <pembaruan class="mb-4"></pembaruan>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/utils/page-banner';
import Bookmark from '@/components/static-page/term-of-service/bookmark';
import Definisi from '@/components/static-page/term-of-service/definisi';
import Akun from '@/components/static-page/term-of-service/akun';
import Harga from '@/components/static-page/term-of-service/harga';
import HargaPaket from '@/components/static-page/term-of-service/harga-paket';
import ListingProperti from '@/components/static-page/term-of-service/listing-properti';
import Penolakan from '@/components/static-page/term-of-service/penolakan';
import Pelepasan from '@/components/static-page/term-of-service/pelepasan';
import GantiRugi from '@/components/static-page/term-of-service/ganti-rugi';
import Hukum from '@/components/static-page/term-of-service/hukum';
import Pembaruan from '@/components/static-page/term-of-service/pembaruan';

export default {
  name: 'term-of-service',
  components: {
    Banner,
    Bookmark,
    Definisi,
    Akun,
    Harga,
    HargaPaket,
    ListingProperti,
    Penolakan,
    Pelepasan,
    GantiRugi,
    Hukum,
    Pembaruan,
  },
  computed: {
    bannerText() {
      return this.$t('tos.title');
    },
  },
};
</script>
