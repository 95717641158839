<template>
  <div class="container bg-white">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.bookmark.title') }}</h3>
        <div class="divider"></div>
        <ol type="1">
          <li>
            <a href="#definisi">{{ $t('tos.definisi.title') }}</a>
          </li>
          <li>
            <a href="#akun">{{ $t('tos.akun.title') }}</a>
          </li>
          <li>
            <a href="#harga">{{ $t('tos.harga.title') }}</a>
          </li>
          <li>
            <a href="#harga-paket">{{ $t('tos.hargaPaket.title') }}</a>
          </li>
          <li>
            <a href="#listing-properti">{{ $t('tos.listingProperti.title') }}</a>
          </li>
          <li>
            <a href="#penolakan">{{ $t('tos.penolakan.title') }}</a>
          </li>
          <li>
            <a href="#pelepasan">{{ $t('tos.pelepasan.title') }}</a>
          </li>
          <li>
            <a href="#ganti-rugi">{{ $t('tos.gantiRugi.title') }}</a>
          </li>
          <li>
            <a href="#hukum">{{ $t('tos.hukum.title') }}</a>
          </li>
          <li>
            <a href="#pembaruan">{{ $t('tos.pembaruan.title') }}</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bookmark',
};
</script>
