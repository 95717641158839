<template>
  <section class="page--banner">
    <div class="title--text">
      {{ $t(bannerText) }}
    </div>
    <img src="@/assets/img/home/banner-2.svg" class="banner--image" />
  </section>
</template>

<script>
export default {
  props: ['bannerText'],
};
</script>
